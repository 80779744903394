import React, { useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

// icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { styled } from "@mui/material/styles";

// hooks
import { useStudy } from "../hooks";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Categories = (props) => {
  const [displayCategories, setDisplayCategories] = useState([]);
  const [parent, setParent] = useState(null);
  const study = useStudy();

  // disable checkbox and prevent user from firing multiple asynchronous updates
  const [disabled, setDisabled] = useState(false);

  const updateDisplayCategories = () => {
    if (parent === null) {
      let roots = [];
      study.categories.forEach((category, key) => {
        if (category.parent === null) {
          roots.push(category);
        }
      });

      setDisplayCategories(roots);

      return roots;
    } else {
      let newCategories = [];

      const children = study.categories
        .get(parent)
        .children.filter((c) => study.categories.get(c.category)?.published)
        .map((c) => c.category);

      for (let x = 0; x < children.length; x++) {
        if (children.length > 0) {
          newCategories.push(study.categories.get(children[x]));
        }
      }

      newCategories = newCategories.filter((c) => Boolean(c));

      setDisplayCategories(newCategories);

      return newCategories;
    }
  };

  const upArrow = () => {
    if (parent !== null) {
      return (
        <IconButton onClick={() => toggleUpArrow(parent)}>
          <KeyboardArrowUpIcon />
        </IconButton>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    updateDisplayCategories();
  }, [parent, study.categories, setDisplayCategories]);

  const toggleUpArrow = (_id) => {
    if (_id === null) return;
    const category = study.categories.get(_id);
    let showCategories = new Map();

    if (category.parent !== null) {
      let parent = study.categories.get(category.parent);

      study.categories.forEach((cat, key) => {
        if (parent.children.some((child) => child.category === key)) {
          showCategories.set(key, cat);
        }
      });

      setParent(category.parent);
    } else {
      study.categories.forEach((cat, key) => {
        if (cat.parent === null) {
          showCategories.set(key, cat);
        }
      });

      setParent(null);
    }
  };

  const categoryHasPublicChildren = (_id) => {
    const category = study.categories.get(_id);

    return (
      category.children
        .filter((c) => study.categories.get(c.category))
        .map((c) => c.category).length > 0
    );
  };

  const updateParent = (_id) => {
    if (!categoryHasPublicChildren(_id)) return;
    setParent(_id);
  };

  const listItemClickAction = (category) => {
    if (disabled) return null;

    return category?.snippets?.length > 0
      ? async () => await addFlashcards(category._id)
      : () => updateParent(category._id);
  };

  const addFlashcards = async (_id) => {
    setDisabled(true);

    await study.flagFlashards(_id);

    setDisabled(false);
  };

  const countPublishedSnippets = (_id) => {
    const category = study.categories.get(_id);

    return category.snippets.filter((s) => s.published).length;
  };

  const setClassName = () => {
    if (parent === null) {
      return "browse_decks";
    } else if (displayCategories[0]?.snippets?.length === 0) {
      return "browse_chapters";
    } else {
      return "activate_flashcards";
    }
  };

  const setCheckBoxClassName = (index) => {
    if (parent !== null && index === 0) {
      return "category_checkbox";
    }

    return "";
  };

  return (
    <>
      <DrawerHeader className={"categories_navigator"}>
        {upArrow()}
        <IconButton onClick={() => props.toggleDrawer()}>
          {<HighlightOffIcon />}
        </IconButton>
      </DrawerHeader>

      <List className={setClassName()}>
        {displayCategories.map((category, index) => (
          <ListItemButton
            key={category._id}
            onClick={listItemClickAction(category)}
          >
            <Grid container alignItems="center" direction="row" wrap="nowrap">
              <Grid item>
                <Checkbox
                  checked={Boolean(category?.flagged)}
                  disabled={disabled}
                  className={setCheckBoxClassName(index)}
                  sx={{
                    width: 50,
                    height: 50,
                    color: category?.children?.length > 0 ? "gray" : "default",
                    opacity: category?.children?.length > 0 ? 0.2 : 1,
                  }}
                />
              </Grid>
              <Grid item>
                <ListItemText
                  primary={category.title}
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                />
              </Grid>
            </Grid>
            {category.snippets && countPublishedSnippets(category._id) > 0
              ? `(${countPublishedSnippets(category._id)})`
              : null}
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export default Categories;
