import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";

// Hooks
import { useStudy } from "../hooks";

// Sections
import Header from "../sections/Header";
import Study from "../sections/Study";

import { config } from "../config";

const COPYRIGHT_HEIGHT = config.COPYRIGHT_HEIGHT;

const theme = createTheme({
  palette: {
    primary: {
      main: "#123", // This will be the color of your AppBar
    },
  },
  zIndex: {
    drawer: 1200,
  },
});

function Dashboard() {
  const [categories, setCategories] = useState([]);
  const [parent, setParent] = useState("root");
  const hasLoaded = useRef(false); // Use useRef to track if load has been called

  const study = useStudy();

  const load = async () => {
    if (!study.loaded && !study.loading && !hasLoaded.current) {
      hasLoaded.current = true; // Set the ref to true to indicate loading has started
      await study?.load();
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (!study || !study?.loaded) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 1300, // Ensure it is above other elements
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Header
        categories={categories}
        parent={parent}
        setParent={setParent}
        setCategories={setCategories}
      />

      <Study />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: `${COPYRIGHT_HEIGHT}px`,
          position: "fixed",
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Stack direction="row" spacing={2} mb={2}>
          <Copyright />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      pb={2}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">Radki</Link> {new Date().getFullYear()}
    </Typography>
  );
}

export default Dashboard;
