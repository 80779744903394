import { createContext, useContext, useMemo, useState } from "react";

import { postLogin, postLogout, checkCookie } from "../api/index";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState();
  const [_id, setId] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [role, setRole] = useState();

  // call this function when you want to authenticate the user
  const login = async ({ email, password }) => {
    try {
      const payload = await postLogin({ email, password });

      if (payload?.status === 1) {
        const { email, firstName, lastName, _id, role } = payload.data;
        setEmail(email);
        setIsLoggedIn(true);
        setFirstName(firstName);
        setLastName(lastName);
        setId(_id);
        setRole(role);
      }

      return payload;
    } catch (err) {
      return err;
    }
  };

  const checkLogin = async () => {
    try {
      const payload = await checkCookie();

      console.log(payload);

      if (payload.status === 1) {
        const { email, firstName, lastName, _id, role } = payload.data;

        setEmail(email);
        setFirstName(firstName);
        setLastName(lastName);
        setIsLoggedIn(true);
        setId(_id);
        setRole(role);
      } else {
        setEmail("");
        setFirstName("");
        setLastName("");
        setIsLoggedIn(false);
        setId("");
        setRole("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // call this function to sign out logged in user
  const logout = async () => {
    setEmail(null);
    setIsLoggedIn(false);
    setFirstName(null);
    setLastName(null);
    setId(null);
    await postLogout();
  };

  const value = useMemo(
    () => ({
      email,
      role,
      isLoggedIn,
      login,
      logout,
      firstName,
      lastName,
      checkLogin,
      _id,
    }),
    [email, role, isLoggedIn, firstName, lastName, _id]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
