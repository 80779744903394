export const checkParentTreeCompletion = (_id, hashedCategories) => {
  if (_id === null) return;
  let childrenAllFlagged = true;
  const parent = hashedCategories.get(_id)?.parent;

  if (parent === null || !hashedCategories.has(parent)) return;

  for (let x = 0; x < hashedCategories.get(parent).children.length; x++) {
    const childId = hashedCategories.get(parent).children[x].category;
    const published = hashedCategories.get(childId)?.published || false;

    if (published === false) continue;

    if (Boolean(hashedCategories.get(childId)?.flagged) === false) {
      childrenAllFlagged = false;
      break;
    }
  }

  hashedCategories.get(parent).flagged = childrenAllFlagged;

  checkParentTreeCompletion(parent, hashedCategories);
};
