import { getStartAndEndTimes } from "../../utils/time";

export const filterFlashcardsToStudy = ({ option, flashcards }) => {
  let cardsToStudy = [];
  const { startOfDay, endOfDay } = getStartAndEndTimes(new Date());

  flashcards.forEach((flashcard) => {
    if (!flashcard.active) return;

    const dueDate = new Date(flashcard.due);

    if (option === "all" && (flashcard.due === null || dueDate < endOfDay)) {
      cardsToStudy.push(flashcard);
    } else if (option === "new" && flashcard.due === null) {
      cardsToStudy.push(flashcard);
    } else if (
      option === "due" &&
      dueDate >= startOfDay &&
      dueDate <= endOfDay
    ) {
      cardsToStudy.push(flashcard);
    } else if (option === "overdue" && dueDate < startOfDay) {
      cardsToStudy.push(flashcard);
    }
  });

  return cardsToStudy;
};
