const { fetchData, createHeaders } = require("./general");

const {
  postLogin,
  postLogout,
  postSignUp,
  checkCookie,
  verifyUser,
  resendEmailVerification,
  updatePassword,
  resetPassword,
} = require("./account");

const { loadMissingFlashcards } = require("./flashcards");

const getPublicCategories = async () => {
  const options = {
    method: "GET",
    headers: createHeaders(),
  };
  const response = await fetchData("/api/user/categories", options);
  return response.data;
};

const getFlashcards = async (snippets) => {
  let ids = encodeURIComponent(JSON.stringify(snippets));
  const options = {
    method: "GET",
    headers: createHeaders(),
  };
  const response = await fetchData(`/api/user/snippets/${ids}`, options);
  return response.data.snippets;
};

/**
 * returns { categories, flags, flashcards }
 */
const loadData = async () => {
  const options = {
    method: "GET",
    credentials: "include",
    headers: createHeaders(),
  };
  try {
    const response = await fetchData("/api/user/load", options);
    return response.data;
  } catch (err) {
    console.log(err);
    return { categories: [], flags: [], flashcards: [] };
  }
};

const loadFlashcards = async (_ids) => {
  const options = {
    method: "POST",
    credentials: "include",
    headers: createHeaders(),
    body: JSON.stringify(_ids),
  };
  const response = await fetchData("/api/user/load/flashcards", options);
  return response.data;
};

const flagCategory = async (_id, status) => {
  const options = {
    method: "POST",
    credentials: "include",
    headers: createHeaders(),
    body: JSON.stringify({ _id, status }),
  };
  try {
    const response = await fetchData(`/api/user/flag/${_id}`, options);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getSnippet = async (_id) => {
  const options = {
    method: "GET",
    credentials: "include",
    headers: createHeaders(),
  };
  try {
    const response = await fetchData(`/api/user/snippet/${_id}`, options);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getCategories = async () => {
  const options = {
    method: "GET",
    headers: createHeaders(),
  };
  const response = await fetchData("/api/user/load", options);
  return response.data;
};

const postDeactiveAll = async () => {
  const options = {
    method: "POST",
    credentials: "include",
    headers: createHeaders(),
  };
  try {
    await fetchData("/api/user/deactivate/all", options);
  } catch (err) {
    console.error(err);
  }
};

const postFlashcardResponse = async (data) => {
  const options = {
    method: "POST",
    credentials: "include",
    headers: createHeaders(),
    body: JSON.stringify(data),
  };
  try {
    const response = await fetchData("/api/user/flashcard/response", options);
    return response.status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export {
  getPublicCategories,
  getCategories,
  getFlashcards,
  postLogin,
  postLogout,
  postSignUp,
  flagCategory,
  getSnippet,
  checkCookie,
  loadData,
  postDeactiveAll,
  postFlashcardResponse,
  updatePassword,
  loadFlashcards,
  resetPassword,
  verifyUser,
  resendEmailVerification,
  loadMissingFlashcards,
};
