import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect, useRef } from "react";
import MarkDown from "../MarkDown";

import ScrollBar from "./ScrollBar";

const paperStyle = {
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "left",
  // margin: props.captionOnly || props.question ? '0' : 'auto',
  // padding: '20px',
};

/**
 * Image component to display a series of images with optional captions and scrolling functionality.
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.snippet - The snippet object containing series of images.
 * @param {number} props.imageIndex - The current index of the image being displayed.
 * @param {number} props.seriesIndex - The current index of the series being displayed.
 * @param {Function} props.setSeriesIndex - Function to set the current series index.
 * @param {Function} props.setImageIndex - Function to set the current image index.
 * @param {boolean} props.isHoveringImg - Flag to indicate if the mouse is hovering over the image.
 * @param {Function} props.setIsHoveringImg - Function to set the hovering state of the image.
 * @param {boolean} props.isHoveringScrollBar - Flag to indicate if the mouse is hovering over the scrollbar.
 * @param {Function} props.setIsHoveringScrollBar - Function to set the hovering state of the scrollbar.
 * @param {boolean} props.scrolling - Flag to indicate if scrolling is in progress.
 * @param {Function} props.setScrolling - Function to set the scrolling state.
 * @param {number} props.imageHeight - The height of the image.
 * @param {Function} props.setImageHeight - Function to set the height of the image.
 * @param {boolean} [props.imageOnly] - Flag to indicate if only the image should be displayed.
 * @param {boolean} [props.captionOnly] - Flag to indicate if only the caption should be displayed.
 * @param {boolean} [props.thumbnail] - Flag to indicate if the image should be displayed as a thumbnail.
 * @returns {JSX.Element} - The rendered Image component.
 */

const Image = (props) => {
  const containerRef = useRef(null);
  const imgRef = useRef(null);
  const scrollbarRef = useRef(null);

  const [wheelLock, setWheelLock] = useState(false);

  const series = props?.snippet?.series;
  const numberOfImages = series?.reduce((acc, s) => acc + s.images.length, 0);

  let showImage = false;
  let showCaption = false;

  if (props?.imageOnly) {
    showImage = true;
    showCaption = false;
  } else if (props?.captionOnly) {
    showImage = false;
    showCaption = true;
  } else {
    showImage = true;
    showCaption = true;
  }

  const {
    snippet,
    imageIndex,
    seriesIndex,
    setSeriesIndex,
    setImageIndex,
    isHoveringImg,
    setIsHoveringImg,
    isHoveringScrollBar,
    setIsHoveringScrollBar,
    scrolling,
    setScrolling,
    imageHeight,
    setImageHeight,
  } = props;

  const imgDimensions = "500px";

  const isThumbnail = props.thumbnail === true;
  const thumbnail = "200px";

  const maxDimension = !isThumbnail ? imgDimensions : props.thumbnail;

  const imageStyle = {
    maxHeight: !isThumbnail ? imgDimensions : thumbnail,
    height: !isThumbnail ? imageHeight : thumbnail,
    width: !isThumbnail ? imageHeight : thumbnail,
    maxWidth: "100%",
    objectFit: "contain",
  };

  const containerStyle = {
    maxHeight: maxDimension,
    maxWidth: maxDimension,
  };

  const handleResize = () => {
    if (containerRef?.current) {
      setImageHeight(containerRef?.current?.offsetWidth);
    }
  };

  const wheelScroll = (e) => {
    try {
      if (!isHoveringImg) return;

      e.preventDefault();

      if (wheelLock === true) return;
      if (props.captionOnly) return;

      let newImageIndex = imageIndex;
      let newSeriesIndex = seriesIndex;

      setWheelLock(true);

      setTimeout(() => {
        setWheelLock(false);
      }, 20);

      if (e.deltaY > 0) {
        if (series[seriesIndex].images.length > imageIndex + 1) {
          newImageIndex = imageIndex + 1;
        } else if (
          imageIndex + 1 === series[seriesIndex].images.length &&
          seriesIndex + 1 < series.length
        ) {
          newImageIndex = 0;
          newSeriesIndex = seriesIndex + 1;
        }
      }

      if (e.deltaY < 0) {
        if (imageIndex > 0) {
          newImageIndex = imageIndex - 1;
        } else if (imageIndex === 0 && seriesIndex - 1 >= 0) {
          newImageIndex = series[seriesIndex - 1].images.length - 1;
          newSeriesIndex = seriesIndex - 1;
        }
      }

      setSeriesIndex(newSeriesIndex);
      setImageIndex(newImageIndex);
    } catch (e) {
      console.debug(e);
    }
  };

  const onMouseMove = (e, jump = false) => {
    const scrollBarMargin = 10;

    if (scrollbarRef.current && (scrolling || jump)) {
      let y =
        e.clientY -
        scrollbarRef.current.getBoundingClientRect().top -
        scrollBarMargin;
      if (e.touches) {
        y =
          e.touches[0].clientY -
          scrollbarRef.current.getBoundingClientRect().top -
          scrollBarMargin;
      }

      let index = Math.floor((y / imageHeight) * numberOfImages);

      let currentGlobalIndex = 0;

      if (index < 0) {
        index = 0;
      }

      if (index >= numberOfImages) {
        index = numberOfImages - 1;
      }

      if (index < 0) {
        index = 0;
      }

      // find new series and image index
      for (let sIndex = 0; sIndex < snippet.series.length; sIndex++) {
        const imagesInSeries = snippet.series[sIndex].images.length;
        // Check if the globalIndex falls within the current series
        if (currentGlobalIndex + imagesInSeries > index) {
          const iIndex = index - currentGlobalIndex;
          setSeriesIndex(sIndex);
          setImageIndex(iIndex);
          return;
        }
        currentGlobalIndex += imagesInSeries; // Update the currentGlobalIndex for the next iteration
      }
    }
  };

  const handleMouseEnter = () => {
    setIsHoveringImg(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringImg(false);
  };

  const onMouseUp = () => {
    setScrolling(false);
  };

  const onMouseDown = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();

    e.cancelBubble = true;
    e.returnValue = false;

    if (isHoveringScrollBar) {
      setScrolling(true);
      onMouseMove(e, true);
    } else if (
      scrollbarRef.current &&
      scrollbarRef.current.contains(e.target)
    ) {
      console.log("Touch started over scrollbar");
      setScrolling(true);
      onMouseMove(e, true);
    }
  };

  const handleMouseScrollBarEnter = () => {
    setIsHoveringScrollBar(true);
  };

  const handleMouseScrollBarLeave = () => {
    setIsHoveringScrollBar(false);
  };

  const onTouchMove = (e) => {
    console.log("moving");
    if (scrolling) {
      onMouseMove(e, true);
    }
  };

  const onTouchStart = (e) => {
    if (scrollbarRef.current && scrollbarRef.current.contains(e.target)) {
      console.log("Touch started over scrollbar");
      setScrolling(true);
      onMouseMove(e, true);
    }
  };

  const onTouchUp = (e) => {
    setScrolling(false);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        // Move to the next image
        if (setImageIndex < snippet.series[seriesIndex].images.length - 1) {
          setImageIndex(setImageIndex + 1);
        } else if (seriesIndex < snippet.serieslength - 1) {
          setImageIndex(0);
          setSeriesIndex(seriesIndex + 1);
        }
        break;
      case "ArrowUp":
        // Move to the previous image
        if (setImageIndex > 0) {
          setImageIndex(setImageIndex - 1);
        } else if (seriesIndex > 0) {
          setSeriesIndex(seriesIndex - 1);
          setImageIndex(snippet.series[seriesIndex - 1].images.length - 1);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    if (numberOfImages > 1) {
      window.addEventListener("wheel", wheelScroll, { passive: false });
      window.addEventListener("keydown", handleKeyDown);

      window.addEventListener("mouseup", onMouseUp);
      window.addEventListener("mousedown", onMouseDown);
      window.addEventListener("mousemove", onMouseMove);

      window.addEventListener("touchstart", onMouseDown);
      window.addEventListener("touchmove", onMouseMove);
      window.addEventListener("touchend touchcancel", onMouseUp);
    }

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);

      window.removeEventListener("wheel", wheelScroll);
      window.removeEventListener("keydown", handleKeyDown);

      window.removeEventListener("mouseup", onMouseUp, { passive: false });
      window.removeEventListener("mousedown", onMouseDown, { passive: false });
      window.removeEventListener("mousemove", onMouseMove, { passive: false });

      window.removeEventListener("touchstart", onMouseDown, { passive: false });
      window.removeEventListener("touchmove", onMouseMove, { passive: false });
      window.removeEventListener("touchend touchcancel", onMouseUp, {
        passive: false,
      });
    };
  }, [numberOfImages]);

  const imageBlock = () =>
    series && (
      <Box style={paperStyle} direction="column">
        <div
          ref={containerRef}
          style={containerStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="image-container"
        >
          <img
            ref={imgRef}
            style={imageStyle}
            src={series[seriesIndex]?.images[imageIndex]?.url}
            alt=""
            onError={(e) => {
              console.error("Error loading image:", e);
            }}
          />
          <div
            ref={scrollbarRef}
            className="scrollbar-container"
            style={{ height: `100%` }}
            onMouseLeave={handleMouseScrollBarLeave}
            onMouseEnter={handleMouseScrollBarEnter}
          >
            <ScrollBar
              imageHeight={imageHeight}
              numberOfImages={numberOfImages}
              {...props}
            />
          </div>
        </div>
        {series.map((series, index) => {
          return series.images.map((image, i) => {
            return <link rel="preload" as="image" href={image.url} key={i} />;
          });
        })}
      </Box>
    );

  const captionBlock = () => {
    const title = series[seriesIndex]?.title;
    const hasTitle = title && title.trim() !== "";
    const titleText = hasTitle ? title : "";
    const captionText = series[seriesIndex]?.caption;
    const hasCaption = captionText && captionText.trim() !== "";
    let colon = "";

    if (!hasTitle) {
      colon = "";
    } else if (hasCaption) {
      colon = ":** ";
    } else if (hasTitle && !hasCaption) {
      colon = "**";
    } else {
      colon = "";
    }

    const text = `${hasTitle ? "**" : ""}${titleText + colon}${captionText}`;

    return <MarkDown>{text}</MarkDown>;
  };

  return (
    <Box>
      {showImage && imageBlock()}
      {showCaption && captionBlock()}
    </Box>
  );
};

export default Image;
