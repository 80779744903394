import { createContext, useContext, useMemo, useState } from "react";

// API
import {
  loadData,
  postDeactiveAll,
  flagCategory,
  loadMissingFlashcards,
} from "../../api/index";

// Functions
import { hashCategories } from "./hashCategories";
import { hashSnippets } from "./hashSnippets";
import { hashFlashcards } from "./hashFlashcards";
import { checkParentTreeCompletion } from "./checkParentTreeCompletion";
import { filterFlashcardsToStudy } from "./filterFlashcardsToStudy";
import { deactivateCategoriesAndFlashcards } from "./deactivateCategoriesAndFlashcards";

const StudyContext = createContext();

export const StudyProvider = ({ children }) => {
  const [flashcards, setFlashcards] = useState(new Map());
  const [flashcardsToStudy, setFlashcardsToStudy] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cardsToCategories, setCardsToCategories] = useState(new Map());
  const [studying, setStudying] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const load = async () => {
    setLoading(true);

    let { flashcards, categories, flags, snippets } = await loadData();

    let hashedSnippets = hashSnippets(snippets);
    // sid:flashcard object pairs
    let hashedFlashcards = hashFlashcards({ flashcards });
    let { hashedCategories, hashedCardsToCategories } = hashCategories({
      categories,
      hashedSnippets,
    });

    // Array of snippet _ids that do not exist (i.e., were added
    // after user already flagged the category)
    let missingCards = [];

    if (flags?.categories?.length) {
      for (let x = 0; x < flags.categories.length; x++) {
        const cid = flags.categories[x].category;
        const flagged = Boolean(flags.categories[x].flagged);

        if (!hashedCategories.has(cid)) continue;

        const category = hashedCategories.get(cid);
        category.flagged = flagged;
        hashedCategories.set(cid, category);

        if (flagged) {
          for (let y = 0; y < category.snippets.length; y++) {
            const sid = category.snippets[y].snippet;

            if (!hashedFlashcards.has(sid)) continue;

            if (hashedSnippets.get(sid)?.published === true) {
              if (!hashedCardsToCategories.has(sid)) {
                hashedCardsToCategories.set(sid, {});
              }
              hashedCardsToCategories.get(sid)[cid] = true;

              // Check if snippet exists in flashcards
              if (!hashedFlashcards.has(sid)) {
                // Add to missingCards array
                missingCards.push(sid);
              }
            }
          }
        }
      }
    }

    const cards = await loadMissingFlashcards(missingCards);

    if (cards) {
      for (let x = 0; x < cards.length; x++) {
        hashedFlashcards.set(cards[x]._id, cards[x]);
      }
    }

    const leafs = categories.filter((c) => c.snippets.length > 0);

    for (let x = 0; x < leafs.length; x++) {
      if (hashedCategories.get(leafs[x].parent)) {
        console.log("Checking parent tree completion");
        if (hashedCategories.get(leafs[x])?.flagged) {
          console.log(hashedCategories.get(leafs[x]).title);
        }
        checkParentTreeCompletion(leafs[x]._id, hashedCategories);
      }
    }

    setCategories(hashedCategories);
    setFlashcards(hashedFlashcards);
    setCardsToCategories(hashedCardsToCategories);
    setLoaded(true);
    setLoading(false);
  };

  const deactivateAll = async () => {
    await postDeactiveAll();

    const { updateCategories, updateFlashcards } =
      deactivateCategoriesAndFlashcards(categories, flashcards);

    setCategories(updateCategories);
    setFlashcards(updateFlashcards);
  };

  const flagFlashards = async (_id, flag) => {
    if (!categories.has(_id)) {
      console.error(`Category with id ${_id} does not exist.`);
      return;
    }

    let updateCategories = new Map(categories);
    const status = Boolean(!updateCategories.get(_id).flagged);
    let hashedCardsToCategories = new Map(cardsToCategories);
    let updateFlashcards = new Map(flashcards);

    const data = await flagCategory(_id, status);

    const flashcardsToAdd = data.flashcards;

    for (let x = 0; x < flashcardsToAdd.length; x++) {
      updateFlashcards.set(flashcardsToAdd[x].snippet, flashcardsToAdd[x]);
    }

    if (updateCategories.has(_id)) {
      updateCategories.set(_id, {
        ...updateCategories.get(_id),
        flagged: status,
      });
    }

    const snippets = updateCategories.get(_id).snippets;

    for (let x = 0; x < snippets.length; x++) {
      if (snippets[x].published === false) continue;
      const snippet = snippets[x].snippet;
      if (!hashedCardsToCategories.has(snippet)) {
        hashedCardsToCategories.set(snippet, {});
      }
      hashedCardsToCategories.get(snippet)[_id] = status;

      const checkIfAnyTrue = (obj) => {
        for (let key in obj) {
          if (obj[key] === true) {
            return true;
          }
        }
        return false;
      };

      if (status === false) {
        if (checkIfAnyTrue(hashedCardsToCategories.get(snippet)) === true) {
          updateFlashcards.get(snippet).active = true;
        } else {
          updateFlashcards.get(snippet).active = false;
        }
      }
    }

    if (updateCategories.get(_id).parent !== null) {
      checkParentTreeCompletion(_id, updateCategories);
    }

    setFlashcards(new Map(updateFlashcards));
    setCategories(new Map(updateCategories));
    setCardsToCategories(new Map(hashedCardsToCategories));
  };

  const study = async (option) => {
    const cardsToStudy = filterFlashcardsToStudy({ option, flashcards });
    await setFlashcardsToStudy(cardsToStudy);
    await setStudying(true);
  };

  const stop = () => setStudying(false);

  const value = useMemo(
    () => ({
      flashcards,
      setFlashcards,
      flashcardsToStudy,
      categories,
      setCategories,
      flagFlashards,
      study,
      studying,
      stop,
      load,
      loading,
      loaded,
      deactivateAll,
    }),
    [flashcards, flashcardsToStudy, categories, studying]
  );

  return (
    <StudyContext.Provider value={value}>{children}</StudyContext.Provider>
  );
};

export const useStudy = () => useContext(StudyContext);
