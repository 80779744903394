export const hashFlashcards = ({ flashcards }) => {
  try {
    let hashedFlashcards = new Map();

    for (let x = 0; x < flashcards.length; x++) {
      const snippet = flashcards[x].snippet;
      if (snippet !== undefined) {
        hashedFlashcards.set(snippet, flashcards[x]);
      }
    }

    return hashedFlashcards;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
