let www = "http://localhost:3001";

// Get the current URL
const currentUrl = window.location.href;

// Check if the current URL contains the substring "flashopedia"
// For switching between localhost and website
if (currentUrl.includes("radki")) {
  www = "";
}

// Utility function to handle fetch requests
const fetchData = async (url, options) => {
  try {
    const response = await fetch(www + url, options);

    if (!response.ok) {
      console.debug(`HTTP error! status: ${response.status}`);
      console.debug("Response:", response);
    }

    // Check if the response body is empty
    const text = await response.text();
    if (!text) {
      return {};
    }

    const payload = JSON.parse(text);

    return payload;
  } catch (error) {
    console.error("Fetch request failed:", error);
    throw error;
  }
};

// Utility function to create headers
const createHeaders = (contentType = "application/json") => ({
  "Content-Type": contentType,
});

exports.fetchData = fetchData;
exports.createHeaders = createHeaders;
