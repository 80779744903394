export const hashCategories = ({ categories, hashedSnippets }) => {
  try {
    let hashedCategories = new Map();
    let hashedCardsToCategories = new Map();

    for (let x = 0; x < categories.length; x++) {
      const cid = categories[x]._id;

      // skip all unpublished categories
      if (categories[x].published === false) continue;

      for (let y = 0; y < categories[x].snippets.length; y++) {
        const sid = categories[x].snippets[y].snippet;

        if (hashedSnippets.get(sid)?.published === true) {
          if (!hashedCardsToCategories.has(sid)) {
            hashedCardsToCategories.set(sid, {});
          }
          hashedCardsToCategories.get(sid)[cid] = false;
        }
      }

      hashedCategories.set(cid, categories[x]);
    }

    return { hashedCategories, hashedCardsToCategories };
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
