export const deactivateCategoriesAndFlashcards = (categories, flashcards) => {
  const updateCategories = new Map(categories);
  const updateFlashcards = new Map(flashcards);

  for (let [key, value] of updateCategories) {
    if (value.flagged === true) {
      value.flagged = false;
      updateCategories.set(key, value);
    }
  }

  for (let [key, value] of updateFlashcards) {
    value.active = false;
    updateFlashcards.set(key, value);
  }

  return { updateCategories, updateFlashcards };
};
