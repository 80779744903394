export const hashSnippets = (snippets) => {
  try {
    let hashedSnippets = new Map();

    for (let x = 0; x < snippets.length; x++) {
      const id = snippets[x]._id;
      if (id !== undefined) {
        hashedSnippets.set(id, snippets[x]);
      }
    }

    return hashedSnippets;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
